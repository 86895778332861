import * as companyInterfaces from '@/interfaces/companyInterfaces'

// 取得表格要使用的Component名稱
export function returnDetailComponent (col_name_en:string):string {
  switch (col_name_en) {
    case 'directors':
    case 'president':
    case 'managers':
      return 'PeopleDetail'
    case 'etax_matters':
      return 'EtaxDetail'
    default:
      return 'DefaultDetail'
  }
}

// 解析表格資料
export function parsePeopleDetail(
  list:Array<companyInterfaces.ICompanyHistoryPeople>,
  compareList:Array<companyInterfaces.ICompanyHistoryPeople>
):Array<companyInterfaces.ICompanyHistoryPeopleCompared> {

  return list.map((data, i) => {
    if (compareList[i] == null) {
      return <companyInterfaces.ICompanyHistoryPeopleCompared>{
        name: data.name,
        title: data.title,
        stocks: data.stocks,
        ROL: data.ROL,
        nameIsDiff: true,
        titleIsDiff: true,
        contributionIsDiff: true,
        stocksIsDiff: true,
        ROLIsDiff: true
      }
    } else {
      let nameIsDiff = true, titleIsDiff = true, contributionIsDiff = true, stocksIsDiff = true, ROLIsDiff = true
      // 找尋是否有相同「姓名」的資料（物件）
      let currentName = data.name
      try {
        // 去除空格並轉小寫
        currentName = currentName.replace(/\s+/g, "")
        currentName = currentName.toLowerCase()
      } catch (e) {}
      let findItem = compareList.find(d => {
        let compareName = d.name
        try {
          // 去除空格並轉小寫
          compareName = compareName.replace(/\s+/g, "")
          compareName = compareName.toLowerCase()
        } catch (e) {}
        return currentName === compareName
      })
      if (findItem) {
        // 如果有的話再各別比對物件裡各欄位是否相同
        if (data.name == findItem.name) {
          nameIsDiff = false
        }
        if (data.title == findItem.title) {
          titleIsDiff = false
        }
        if (data.contribution == findItem.contribution) {
          contributionIsDiff = false
        }
        if (data.stocks == findItem.stocks) {
          stocksIsDiff = false
        }
        if (data.ROL == findItem.ROL) {
          ROLIsDiff = false
        }
      }
      return <companyInterfaces.ICompanyHistoryPeopleCompared>{
        name: data.name,
        title: data.title,
        contribution: data.contribution,
        stocks: data.stocks,
        ROL: data.ROL,
        // nameIsDiff: data.name != compareList[i].name,
        // titleIsDiff: data.title != compareList[i].title,
        // stocksIsDiff: data.stocks != compareList[i].stocks,
        // ROLIsDiff: data.ROL != compareList[i].ROL
        nameIsDiff,
        titleIsDiff,
        contributionIsDiff,
        stocksIsDiff,
        ROLIsDiff
      }
    }
  })
}

// 解析表格資料
export function parseEtaxDetail(
  list:Array<companyInterfaces.ICompanyHistoryEtax>,
  compareList:Array<companyInterfaces.ICompanyHistoryEtax>
):Array<companyInterfaces.ICompanyHistoryEtaxCompared> {
  return list.map((data, i) => {
    if (compareList[i] == null) {
      return <companyInterfaces.ICompanyHistoryEtaxCompared>{
        code: data.code,
        title: data.title,
        codeIsDiff: true,
        titleIsDiff: true
      }
    } else {
      let codeIsDiff = true, titleIsDiff = true
      // 找尋是否有相同「代碼」的資料（物件）
      let findItem = compareList.find(d => data.code === d.code)
      if (findItem) {
        // 如果有的話再各別比對物件裡除了「代碼」以外的其他欄位是否相同
        codeIsDiff = false
        if (data.title == findItem.title) {
          titleIsDiff = false
        }
      }
      return <companyInterfaces.ICompanyHistoryEtaxCompared>{
        code: data.code,
        title: data.title,
        // codeIsDiff: d.code != compareList[i].code,
        // titleIsDiff: d.title != compareList[i].title
        codeIsDiff,
        titleIsDiff
      }
    }
  })
}