<template>
  <div
    ref="chart"
    style="width:650px;height:380px;border:1px solid #c7c7c7;padding:10px">
  </div>
</template>

<script>
import echarts from 'echarts'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'trades': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
  },
  methods: {
    renderChart (currentTradeList) {

      // console.log(dom.offsetHeight)
      let chartDom = this.$refs.chart// 取得渲染圖表的dom
      const chart = echarts.init(chartDom)

      const chartOptions = {
        title: {
          text: `${this.basic.company_name} 歷年實績統計`
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter (d) {
            // tooltip自訂格式
            let label0 = ''
            let label1 = ''
            for (let key in codes.IMPORT_AND_EXPORT_DATA) {
              if (codes.IMPORT_AND_EXPORT_DATA[key].chartValue === d[0].value) {
                label0 = codes.IMPORT_AND_EXPORT_DATA[key].label
                break
              }
            }
            for (let key in codes.IMPORT_AND_EXPORT_DATA) {
              if (codes.IMPORT_AND_EXPORT_DATA[key].chartValue === d[1].value) {
                label1 = codes.IMPORT_AND_EXPORT_DATA[key].label
                break
              }
            }
            return `${d[0].axisValue}年<br>${d[0].seriesName}: ${label0}<br>${d[1].seriesName}: ${label1}`
          }
        },
        legend: {
          data: ['進口實績', '出口實績'],
          top: '30px'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '金額(單位:百萬美元)',
          // nameLocation: 'center',
          nameTextStyle: {
            padding: [28,0,0,0],
            align: 'right',
            verticalAlign: 'top'
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          name: '年份',
          nameTextStyle: {
            padding: [-8,0,0,0],
            align: 'right',
            verticalAlign: 'top'
          },
          data: currentTradeList.map(d => d.year).reverse()
        },
        series: [
          {
            name: '進口實績',
            type: 'bar',
            data: currentTradeList.map(d => {
              return codes.IMPORT_AND_EXPORT_DATA[d.import].chartValue || 0
            }).reverse()
          },
          {
            name: '出口實績',
            type: 'bar',
            data: currentTradeList.map(d => {
              return codes.IMPORT_AND_EXPORT_DATA[d.export].chartValue || 0
            }).reverse()
          }
        ]
      }

      chart.setOption(chartOptions)

      window.onresize = function () {
        chart.resize()
      }
      window.dispatchEvent(new Event('resize'))
    }
  },
  mounted () {
    // 渲染圖表
    if (this.trades && this.trades.list && this.trades.list.length) {
      this.renderChart(this.trades.list)
    }
  },
  watch: {
    'trades': {
      handler (d) {
        if (d && d.list && d.list.length) {
          let trades = JSON.parse(JSON.stringify(d))
          // 渲染圖表
          this.renderChart(trades.list)
        }
      },
    },
  }
}
</script>