<template>
  <div
    ref="chart"
    style="width:650px;height:380px;border:1px solid #c7c7c7;padding:10px">
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts'

export default {
  props: {
    'basic': {
      required: true,
      default () {
        return {}
      }
    },
    'revenue_history': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
  },
  methods: {
    renderChart (tableData) {
      // console.log(dom.offsetHeight)
      let currentTableData = JSON.parse(JSON.stringify(tableData))
      currentTableData.reverse()

      let chartDom = this.$refs.chart// 取得渲染圖表的dom
      const chart = echarts.init(chartDom)

      const chartOptions = {
        title: {
          text: `${this.basic.company_name} 歷年實績統計`
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['(月)營收', '去年同期(月)營收'],
          top: '30px'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: currentTableData.map(d => `${d.year}-${d.month}`)
          // data: currentTableData.map(d => moment(d.year).format('MM月'))
          data: currentTableData.map(d => moment(d.year).format('YYYY年MM月'))
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '(月)營收',
            type: 'line',
            data: currentTableData.map(d => d.value_this_year)
          },
          {
            name: '去年同期(月)營收',
            type: 'line',
            data: currentTableData.map(d => d.value_last_year)
          }
        ]
      }

      chart.setOption(chartOptions)

      window.onresize = function () {
        chart.resize()
      }
      window.dispatchEvent(new Event('resize'))
    }
  },
  mounted () {
    // 渲染圖表
    if (this.revenue_history && this.revenue_history.list && this.revenue_history.list.length) {
      this.renderChart(this.revenue_history.list)
    }
  },
  watch: {
    'revenue_history': {
      handler (d) {
        if (d && d.list && d.list.length) {
          let revenue_history = JSON.parse(JSON.stringify(d))
          // 渲染圖表
          this.renderChart(revenue_history.list)
        }
      },
    },
  }
}
</script>